<template>
  <div class="row top_header">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h5 class="card-title">Search</h5>
        </template>
        <div>
          <form class="form-horizontal" v-on:submit.prevent="submit_select">
            <div class="row">
              <div class="col-sm-10">
                <fg-input placeholder="Start typing" v-model="query">
                  <template v-slot:helpBlock>

                  </template>
                </fg-input>
                <AutoComplete apicall="/company/suggestions" :suggestions="items" v-model:query="query"
                  @update:suggestionSelected="perform_search" />
              </div>
            </div>
          </form>
          <span class="form-text pull-right">Search for a Ticker or Company </span>
        </div>
      </vnud-card>
      <CompanyInfo v-if="ticker" v-model:symbol="ticker"> </CompanyInfo>

      <NewsTimeline v-if='api_call' :apicall="api_call"></NewsTimeline>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import globalController from "@/globalController.js";
import { Checkbox, Radio } from "@/components/index";
import { AutoComplete } from "@/components";
import CompanyInfo from "@/views/dashboard/company/CompanyInfo.vue";
import NewsTimeline from "@/views/dashboard/news/NewsTimeline.vue";

export default {
  components: {
    NewsTimeline,
    AutoComplete,
    CompanyInfo,
  },
  watch: {
    query(newValue, oldValue) {
      console.log(" PERFORM SEARCH " + newValue);
    },
  },
  created() {

    setTimeout(() => {
      const queryParams = this.$route.query;
      if (!queryParams.q)
        return;

      this.query = queryParams.q;
      this.submit_select();
    }, 50);
  },
  methods: {
    perform_search() {
      this.$router.push(globalController.get_route_ticker(this.query));      
    },
    loadTicker(ticker) {
      if (!ticker)
        return;

      if (this.tickers.length != 0 && !this.tickers.includes(ticker)) {
        ticker = this.tickers[0];
      }

      this.ticker = ticker;
      console.log(" TICKER " + ticker);
    },
    overwriteTickers(newTickers) {
      // Method to overwrite the tickers array
      this.tickers = newTickers;
      console.log("Tickers updated:", this.tickers);
    },
    submit_select() {
      console.log(" SUBMIT " + this.query);
      //this.loadTicker(this.query);
      this.api_call = "/news/search/" + this.query + "?";
    },
  },
  data() {
    return {
      api_call: null,
      query: "",
      tickers: [],
      ticker: false,
      selected_ticker: "",
      checkboxes: {
        custom1: false,
        custom2: true,
        first: true,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true,
      },
    };
  },
};
</script>
<style scoped>
.top_header {
  z-index: 9999;
}

.autocomplete {
  z-index: 9999;
}

ul {
  position: relative !important;
}

.card-body {
  padding: 0px !important;
  margin: 0px;
}

.form-control {
  color: black !important;
}

</style>
