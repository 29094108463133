<template>

  <div class="card-header">
    <h4 class="card-title">Prompts
      <i class="pointer pull-right fal fa-refresh" @click="loadData()"></i>
    </h4>
    <span v-if="user.my_debug_interface" class="pull-right ">
      <a :href="getApiEntry()" target="_blank">
        &nbsp;&nbsp;<i class="fa fa-gear"></i> OPEN API</a>
    </span>
    <p class="category">Processing and processed</p>
  </div>
  <div class="card-content">
    <div class="table-full-width table-tasks">
      <table class="table-prompts">
        <tbody>
          <Prompt :showDebug="user.my_debug_interface" v-for=" task in tasks" :key="task.title" :task="task"
            :index="task.id" @on-edit="handleTaskEdit" @on-delete="handleTaskDelete" @on-debug="handleDebug">

            <span v-if="user.my_debug_interface" class="pull-right ">
              <a :href="getPromptApi(task)" target="_blank">
                &nbsp;&nbsp;<i class="fa fa-gear"></i> OPEN API</a>
            </span>
            <div class="clearfix" />
          </Prompt>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer">
    <hr />
    <div class="stats">

    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

import Prompt from "./Prompt.vue";
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import globalConfig from "@/globalConfig.js";

export default {
  components: {
    Prompt,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    })
  },
  data() {
    return {
      reloadInterval: null,
      tasks: [
        {
          title:
            'Test Prompt',
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  unmounted() {
    // Clean up interval when the component is destroyed
    this.stopAutoReloading();
  },
  methods: {
    getPromptApi(task) {
      let apiCall = globalConfig.getApiPath('/prompts/query?id=' + task);
      return apiCall;
    },
    handleDebug(task_id) {
      const index = this.tasks.findIndex(t => t.id === task_id);
      let task = this.tasks[index];
      window.open(this.getPromptApi(task.id))
    },
    getApiEntry() {
      return globalConfig.getApiPath(this.getApiCall());
    },
    getApiCall() {
      let api_call = "/prompts/query?status=INDEX&type__in=PORTFOLIO,email_prompt&status=PROCESSED&order_by=-creation_date&limit=20&username=" + this.user.username;
      return api_call;
    },
    loadData() {
      console.log("PROMPT LOADING ");
      if (!this.logged_in)
        return;

      globalController.api_call(
        this.getApiCall(),
        (result) => {
          this.tasks = result.prompts

          this.startAutoReloading();
          console.log("Prompts result");
        },
        (error) => {
          console.log("Failed loading: Error loading prompts");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    reloadUnprocessedTasks() {
      this.tasks.forEach((task) => {
        if (task.status === "PROCESSED")
          return;

        const api_call = `/prompts/get/${task.id}`; // Endpoint to fetch a single task
        globalController.api_call(
          api_call,
          (result) => {
            const updatedTask = result.prompts[0];
            const index = this.tasks.findIndex(t => t.id === task.id);
            if (index !== -1) {
              this.tasks[index] = updatedTask; // Ensure reactivity
            }
            console.log(`Task ${task.id} reloaded`);
          },
          (error) => {
            console.error(`Failed reloading task ${task.id}:`, error);
          }
        );
      });
    },
    startAutoReloading() {
      // Clear any existing interval
      this.stopAutoReloading();

      // Set a new interval to reload tasks every 15 seconds
      this.reloadInterval = setInterval(() => {
        this.reloadUnprocessedTasks();
      }, 1000);

      console.log("Started automatic reloading every 15 seconds.");
    },
    stopAutoReloading() {
      if (this.reloadInterval) {
        clearInterval(this.reloadInterval);
        this.reloadInterval = null;
        console.log("Stopped automatic reloading.");
      }
    },
    handleTaskEdit(task_id) {
      const task = this.tasks.find(item => item.id === task_id);
      if (task) {
        this.$emit("edit", task);
      }
      //alert(`You want to edit prompt: ${JSON.stringify(this.tasks[index])}`);
    },
    handleTaskDelete(task_id) {
      const task = this.tasks.find(item => item.id === task_id);

      let api_call = "/prompts/rm?id=" + task.id;
      globalController.api_call(
        api_call,
        (result) => {
          task.status = "deleted";
          /*
                    utils.runToast(
                      ` Deleted `,
                      "now-ui-icons ui-1_bell-53",
                      "bottom-right",
                      "info"
                    );          
          */
        },
        (error) => {
          console.log("Failed loading: Error loading prompts");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
      //alert(`You want to delete prompt: ${JSON.stringify(this.tasks[index])}`);
    },
  },
};
</script>
<style>
.table-prompts {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
