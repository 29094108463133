<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h4 v-if="company" class="card-title ticker">
            {{ company.company_name }}
            <i v-if="!isLoaded" class="fa fa-gear fa-spin"></i>
          </h4>
          <h4 v-else>Company not found 😢</h4>
        </template>
      </vnud-card>
    </div>
  </div>
  <div class="row" v-if="company">
    <div class="col-md-12" v-for="(symbol, index) in company.exchange_tickers" :key="symbol">
      <CompanyInfo :symbol="symbol" :index="index"></CompanyInfo>
      <CompanyStock :symbol="symbol"  addYear addMonth></CompanyStock>
      <StockChartRangeSelector :full_ticker="symbol" />

      <CompanyRelated :full_ticker="symbol"></CompanyRelated>

      <NewsTimeline :symbol="symbol"></NewsTimeline>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

import CompanyInfo from "@/views/dashboard/company/CompanyInfo.vue";
import CompanyRelated from "@/views/dashboard/company/CompanyRelated.vue";
import CompanyStock from "@/views/dashboard/company/CompanyStock.vue";

import NewsTimeline from "@/views/dashboard/news/NewsTimeline.vue";
import StockChartRangeSelector from "@/components/stockcharts/StockChartRangeSelector.vue";

import {
  Table as NTable,
} from "@/components";

export default {
  components: { NewsTimeline, CompanyInfo, CompanyRelated, StockChartRangeSelector, CompanyStock },
  created() {
    this.loadData();
  },
  props: {
    full_ticker: String,
  },
  setLoading() { },
  data() {
    return {
      ticker: "NASDAQ:NVDA",
      company: this.getDefaultCompany(),
      isLoaded: false,
    };
  },
  watch: {
    "$route.query": {
      handler(newQuery, oldQuery) {
        console.log(" Reload since it changed ");

        this.loadData();
      },
      deep: true, // Allows deep watching of nested properties
      immediate: true, // If you want the handler to be called immediately
    },
  },
  methods: {
    getDefaultCompany() {
      return {
        company_name: "Loading...",
        exchange_tickers: [],
      };
    },
    async loadData() {
      this.isLoaded = false;      
      
      const queryParams = this.$route.query;
      let apiCall = "/company/query?";
      let l = apiCall.length;

      if (queryParams.id)
        apiCall += "id=" + encodeURIComponent(queryParams.id);

      if (queryParams.name)
        apiCall += "company_name=" + encodeURIComponent(queryParams.name);

      if (this.full_ticker) {
        document.title = this.full_ticker;
        apiCall += "exchange_tickers=" + encodeURIComponent(this.full_ticker);
      } else {
        if (queryParams.symbol) {
          document.title = queryParams.symbol;
          apiCall += "exchange_tickers=" + encodeURIComponent(queryParams.symbol);
        }
      }

      // We don't have anything to load
      if (apiCall.length == l) {
        this.$router.push("/company/explore?nothing_here=1");
        return;
      }

      globalController.api_call(
        apiCall,
        (result) => {
          this.isLoaded = true;

          this.company = result.companies.length ? result.companies[0] : null;

          document.title += " " + this.company.company_name;

          console.log("Company result: Result ");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
};
</script>
<style></style>
