<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card noFooterLine>
        <template v-slot:header>
          <h4 class="card-title ticker margin_local">
            {{ title }}
            <i v-if="!isLoaded" class="fa fa-gear fa-spin"></i>
          </h4>

        </template>
        <template v-slot:default>

          <div class="row" v-if="full_ticker">
            <div class="margin_bottom">
              <div v-for="tickers in related_tickers.slice(0, 20)" :key="tickers" class="pull-left">
                <n-button v-if="!tickers.exchange_tickers.includes($route.query.symbol)"
                  @click="gotoTicker(tickers.exchange_tickers[0])" type="outline-primary" size="sm">
                  <small>{{ tickers.company_name }}</small>
                </n-button>
              </div>
            </div>
          </div>

          <div class="row" v-if="api_call">
            <div class="margin_local">
              <div v-for="company in companies" :key="company" class="pull-left">
                <n-button @click="gotoTicker(company.exchange_tickers[0])" type="outline-primary" size="sm">
                  <small>{{ company.company_name }}</small>
                </n-button>
              </div>
            </div>
          </div>

        </template>
        <template v-slot:footer></template>

      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import globalController from "@/globalController.js";

import utils from "@/globalUtil.js";

export default {
  components: {
  },
  props: {
    full_ticker: String,
    title: {
      type: String,
      default: "Related companies",
    },
    api_call: {
      type: String,
      default: null,
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      isLoaded: false,
      related_tickers: [],
      companies: [],
    };
  },
  methods: {
    gotoTicker(ticker) {      
      this.$router.push(globalController.get_route_ticker(ticker));
    },
    async loadData() {
      this.isLoaded = false;

      let apiCall;
      if (this.full_ticker)
        apiCall = "/company/get_related/" + this.full_ticker + "?limit=25";
      else
        apiCall = this.api_call;

      globalController.api_call(
        apiCall,
        (data) => {
          this.isLoaded = true;
          if (!data)
            return;

          if (this.full_ticker && data.result.length > 0) {
            this.related_tickers = data.related_companies;
          } else {
            this.companies = data.companies;
          }
        },
        (error) => {
          console.log("Failed loading: Error loading related tickers");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  },
};
</script>
<style scoped>
.margin_bottom {
  margin-left: 10px;
  margin-top: -20px;
  margin-bottom: -20px;
  margin-right: 10px;
}

.margin_local {
  margin-left: 10px;
  margin-right: 10px;
}

.pull-left {
  float: left;
  margin-left: 5px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* Adjust the spacing between items */
  padding: 10px;
  /* Optional: Add padding around the container */
}

.n-button {
  flex-grow: 1;
  /* You can also add min-width if you want each button to have a minimum size */
  min-width: 80px;
}
</style>
