<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <div class="row pull-right">
            <div class="col-sm-10 checkbox-radios">
              <checkbox :disabled="!watchlistLoaded || !logged_in" v-model="checkboxes.watching">Watch this stock
              </checkbox>
              <small>
                <router-link to="/register?watch_stock=company" v-if="!logged_in">
                  Register or login to activate
                </router-link>
              </small>
            </div>
          </div>

          <h4 class="card-title ticker">
            {{ exchange }} : {{ ticker }} <i v-if="!isLoaded" class="fa fa-gear fa-spin"></i>
          </h4>

        </template>
        <div>
          <div class="row" v-if="index <= 0">
            <label class="col-sm-1 col-form-label">
            </label>
            <div class="col-sm-11">
              <collapse v-if="info.gics_sector">
                <collapse-item :id="'cat' + info.id" title="Summary">
                  <template v-slot:title>
                    <i class="fal fa-info-circle fa-xl"></i>
                    {{ info.gics_sector }} / {{ info.gics_sub_industry }}
                  </template>
                  <span class="form-local" v-html="markdown(info.ai_summary)"></span>
                  <span class="form-local" v-if="!info.ai_summary">
                    <span v-if="info.tools[0].function?.arguments?.summary">                      
                      <span v-html="markdown(info.tools[0].function.arguments.summary)"></span>
                    </span>
                    <span v-else>
                      {{ info.long_business_summary }}
                    </span>
                  </span>
                </collapse-item>
              </collapse>


              <collapse v-if="review?.ai_summary">
                <collapse-item :id="'review' + info.id" title="Review" active>
                  <template v-slot:title>
                    <i class="fal fa-analytics fa-xl"></i> Recent News Analysis
                  </template>
                  <span class="form-local">
                    <span class="form-local" v-html="markdown(review.ai_summary)"></span>
                  </span>
                  <span class="pull-right" style="margin-right: 35px;"><small>{{ timeAgo(review.ai_upload_date) }}
                    </small></span>
                </collapse-item>
              </collapse>
              <p />
              <a v-if="info.website" :href="info.website" target="_blank"><i class="fal fa-external-link"></i>
                Website</a>
            </div>
          </div>

          <span v-if="user.my_debug_interface" class="pull-right">
            <a :href="getDebugApiEntry('/company/query_prompts?company_id=' + info.id
              + '&order_by=-ai_upload_date&only=ai_summary,ai_upload_date&')" target="_blank">
              &nbsp;&nbsp;<i class="fa fa-gear"></i> Review API </a>

            <a :href="getDebugApiEntry('/company/invalidate/' + info.id)" target="_blank">
              &nbsp;&nbsp;<i class="fa fa-refresh"></i> Reindex </a>

            <a :href="getDebugApiEntry('/company/get/' + info.id)" target="_blank">
              &nbsp;&nbsp;<i class="fa fa-gear"></i> Open API </a>

          </span>
          <div class="clearfix" />
        </div>
      </vnud-card>
    </div>
  </div>
</template>
<style>
.ticker {
  text-transform: uppercase;
}
</style>
<script>
import { Checkbox, Radio } from "@/components/index";
import { Collapse, CollapseItem } from "@/components";

import utils from "@/globalUtil.js";

import globalConfig from "@/globalConfig.js";
import globalController from "@/globalController.js";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    Checkbox, Collapse, CollapseItem,
  },
  props: {
    symbol: String,
    index: Number,
  },
  created() {
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  data() {
    return {
      name: "loading...",
      isLoaded: false,
      ticker: "",
      exchange: "",
      review: null,
      watchlistLoaded: false,
      info: this.setDefaultTickerInfo(),

      default_watchlist: null,
      checkboxes: {
        watching: false,
      },
    };
  },
  watch: {
    "checkboxes.watching": {
      handler(newValue, oldValue) {
        if (!this.default_watchlist) {
          debugger;
          return;
        }

        let is_on_list = this.default_watchlist.exchange_tickers.includes(this.symbol);
        if (is_on_list == newValue) return;

        const action = newValue ? "append" : "remove";
        const apicall = `/ticker/user/watchlist/${action}/default/${this.exchange}:${this.ticker}`;

        globalController.api_call(
          apicall,
          (result) => {
            console.log("Ok");
          },
          (error) => {
            console.log("Failed adding to watchlist");
            utils.runToast(
              ` ${error} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
          }
        );
      },
    },
    symbol: {
      immediate: true, // Executes the watcher immediately on component mount
      handler(newValue, oldValue) {
        if (!newValue)
          return;

        this.name = "...loading...";
        this.setDefaultTickerInfo();
        this.loadData(newValue);
      },
    },
  },
  methods: {
    timeAgo(creation_date) {
      return utils.timeAgo(creation_date);
    },
    markdown(src) {
      if (!src)
        return "";

      const regex = /^\s*(Based on|On|Here(?:'s| is)|This is).*(summary|overview|brief|concise).*[:]*\s*$/i;

      /*
      src.split('\n')
        .map(line => {
          console.log(`Line: '${line.trim()}'`);
          console.log(" TEST " + !regex.test(line.trim()));
          return line.trim();
        })
      */

      const filteredText = src
        .split('\n') // Split into lines
        .filter(line => !regex.test(line.trim())) // Remove matching lines
        .join('\n'); // Reassemble lines

      return utils.markdown(filteredText);
    },
    setSymbol(symbol) {
      if (!symbol.includes(":")) {
        this.exchange = "";
        this.ticker = symbol;
        return;
      }

      const parts = symbol.split(':');
      this.exchange = parts[0];
      this.ticker = parts[1];
    },

    setDefaultTickerInfo() {
      this.info = {
        company_name: "loading...",
        website: "",
      };
    },
    async invalidateSymbol(symbol) {
      globalController.api_call(
        "/ticker/invalidate/" + encodeURIComponent(symbol),
        (result) => {
          console.log("Invalidated symbol");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
        }
      );
    },
    getDebugApiEntry(apiCall) {
      return globalConfig.getApiPath(apiCall);
    },
    async loadReview(company_id) {
      globalController.api_call(
        "/company/query_prompts?company_id=" + company_id + "&ai_summary__exists=1&order_by=-ai_upload_date&only=ai_summary,ai_upload_date&",
        (result) => {
          this.review = result.news[0];
        },
        (error) => {
        }
      );
    },

    async loadData(symbol) {
      this.isLoaded = false;
      this.watchlistLoaded = false;
      this.checkboxes.watching = false;
      if (!symbol)
        return;

      this.invalidateSymbol(symbol);
      globalController.api_call(
        "/company/query?exchange_tickers=" + encodeURIComponent(symbol),
        (result) => {
          this.isLoaded = true;
          this.setSymbol(symbol);

          if (result.companies && result.companies.length > 0) {
            this.info = result.companies[0];
            this.loadReview(this.info['id'])

            this.name = this.info.company_name;
            if (!this.info.website) {
              this.info.website = this.info.wikipedia;
            }
          }
          this.loadUserWatchlist("default");
          console.log("Company result");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
    async loadUserWatchlist(listname) {
      globalController.api_call(
        "/ticker/user/watchlist/get/" + listname,
        (result) => {
          this.default_watchlist = result;
          this.watchlistLoaded = true;

          let extic = this.exchange + ":" + this.ticker;
          this.checkboxes.watching = result.exchange_tickers.includes(extic);
          console.log("User result");
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
        }
      );
    },
  },
};
</script>
<style></style>
