<template>
  <div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center ">
        <div class="card-white">
          <h2 class="title text-white">Please have patience with us <i class="fal fa-heart"/> <br></h2>
          
          <div class="description">
            <p>
              Due large Spam attacks, we have to manually approve new accounts.

              <br><br>
              An admin will check your email and approve your account shortly.
            </p>
          </div>

          <h4 class="title text-white"></h4>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import globalController from "@/globalController.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "vnud-pricing",
  data() {
    return {
      email: 'contact@engineer.blue',
      subject: 'Inquiry',
      body: 'Hello, I would like to inquire about tothemoon corporate...',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
    mailtoLink() {
      const mailtoLink = `subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(this.body)}`;
      return mailtoLink;
    },
  },
  methods: {
    addToCart(tier) {
      //debugger;
      if (!this.logged_in) {
        this.$router.push("/register?trial=" + tier);
        return;
      }

      globalController.api_call(
        "/payment/create_checkout_session?product=" + encodeURIComponent(tier),
        async (result) => {
          window.location.href = result.url;
        },
        (error) => {
          console.log("Failed loading: Error loading " + error);
        }
      );

    },
    contactUs() {
      this.$router.push("/contact?" + this.mailtoLink);
    },
  }
};
</script>

<style>
.text-white {
  color: #fff;
}
</style>

<style scoped>
a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>