<template>
  <div v-if="!logged_in" class="pleaze_login">
    <div class="alert alert-primary">
      <router-link to="/register">
        <button type="button" aria-hidden="true" class="close">
          <i class="now-ui-icons ui-1_simple-remove"></i>
        </button>
        <span>
          Please 
          <router-link to="/login">login</router-link> or register to access this functionality.
        </span>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";


export default {
  name: "please-login",
  props: {
  },
  computed: {
    ...mapState({
      logged_in: (state) => state.logged_in,
    }),
  },
  methods: {
    triggerToggle() {
    },
  },
};
</script>
<style scoped>
a {
  color: white !important;
}

.pleaze_login {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
