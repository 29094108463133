<template>
  <router-view></router-view>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Watch for route changes to update meta tags dynamically
    watch(
      () => route.meta,
      (meta) => {
        if (meta) {
          // Update title
          if (meta.title) {
            document.title = meta.title;
          }
          // Update meta description
          const descriptionTag = document.querySelector('meta[name="description"]');
          if (descriptionTag) {
            descriptionTag.setAttribute('content', meta.description || '');
          }

          // Update canonical link
          const canonicalTag = document.querySelector('link[rel="canonical"]');
          if (canonicalTag) {
            canonicalTag.setAttribute('href', meta.canonical || window.location.href);
          } else {
            // Create a canonical tag if it doesn't exist
            const link = document.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('href', meta.canonical || window.location.href);
            document.head.appendChild(link);
          }
        }
      },
      { immediate: true }
    );
  }
};
</script>
