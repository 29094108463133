<template>
  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <h4 class="card-title">Tools</h4>
        </template>
        <span>
          <n-button type="outline" @click="sitemap()">Sitemap <i class="fal fa-globe"></i>
          </n-button>
        </span>
        <span>
          <n-button type="outline" @click="generateRobotsTxt()">robots.txt <i class="fal fa-robot"></i>
          </n-button>
        </span>

        <span>
          <n-button type="outline" @click="sessionLogin()">Session Login <i class="fal fa-user"></i>
          </n-button>
        </span>

      </vnud-card>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <span class="pull-right"><small> </small></span>
          <h4 class="card-title">Userlist <i v-if="!isLoaded" class="pull-right fa fa-gear fa-spin"></i></h4>
        </template>

        <div class="table-responsive" v-if="isFirstLoaded">
          <n-table class="table-shopping" :data="userTable">
            <template v-slot:columns>
              <th>Username</th>
              <th>Email</th>
              <th>is_admin</th>
              <th>is_email validated</th>
              <th>subscription</th>
              <th>
                <n-button class="pull-right" type="outline" @click="loadData"> <i class="fal fa-refresh"></i>
                </n-button>
              </th>
            </template>
            <template v-slot:default="row">
              <td class="pointer">
                {{ row.item.username }}
              </td>
              <td>
                <b>{{ row.item.first_name }} {{ row.item.last_name }}</b>
                {{ row.item.email }}
              </td>
              <td>
                <span>
                  <checkbox :dataId="row.item.username" v-model="row.item.is_admin" class="no_top_fix"
                    @update:model-value="isAdminUpdated">Admin
                  </checkbox>
                </span>
              </td>
              <td>
                <span>
                  <checkbox :dataId="row.item.username" v-model="row.item.is_email_validated" class="no_top_fix"
                    @update:model-value="isEmailValidationUpdated">Validated
                  </checkbox>
                </span>
              </td>

              <td>
                <div v-if="row.item.subscription">
                  {{ row.item.subscription.product_id }} <b>{{ row.item.subscription.status }}</b>
                </div>
              </td>
              <td class="td-name pointer">
                <span class="action_button pull-right" @click="remove_user(row.item.username)">
                  <i class='now-ui-icons ui-1_simple-remove'></i>
                </span>
              </td>

            </template>
          </n-table>
        </div>
        <n-button class="pull-right" type="info" @click="loadData"> <i class="fal fa-refresh"></i> </n-button>
      </vnud-card>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <vnud-card>
        <template v-slot:header>
          <span class="pull-right">
            Priority {{ priority_size }}
            Normal {{ process_size }}
          </span>
          <h4 class="card-title">Services <i v-if="!isQueueLoaded" class="pull-right fa fa-gear fa-spin"></i></h4>
        </template>
        <div class="table-responsive">
          <n-table class="table-shopping" :data="servicesQueueTable">
            <template v-slot:columns>
              <th>Date</th>
              <th>File</th>
            </template>
            <template v-slot:default="row">
              <td class="pointer">
                {{ row.item[1] }}
              </td>
              <td>
                {{ row.item[0] }}
              </td>
            </template>
          </n-table>
        </div>
      </vnud-card>
    </div>

  </div>


</template>

<script>
import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";

import { Checkbox, Radio } from "@/components/index";
import globalConfig from "@/globalConfig.js";

import {
  VnudCard,
  Table as NTable
} from "@/components";

export default {
  props: {
  },
  components: { VnudCard, NTable, Checkbox, },
  created() {
    console.log(" Component creation ")
  },
  data() {
    return {
      priority_size: 0,
      process_size: 0,
      counter: 0,
      intervalId: null,
      isLoaded: false,
      isFirstLoaded: false,
      userTable: [],
      stateInteval: null,
      servicesQueueTable: [],
    };
  },
  mounted() {
    this.loadData();
    this.loadDataServices();
  },
  beforeUnmount() {
  },
  unmounted() {
    if (this.stateInteval)
      clearInterval(this.stateInteval);
  },
  methods: {
    sitemap() {
      window.sitemap();
    },

    generateRobotsTxt() {
      return window.generateRobotsTxt();
    },

    sessionLogin() {
      window.location.href = globalConfig.getApiPath("/user/session");
    },
    isAdminUpdated(checked, username) {
      this.isKeyUpdated(checked, username, "is_admin"); 
    },
    isEmailValidationUpdated(checked, username) {
      this.isKeyUpdated(checked, username, "is_email_validated"); 
    },

    isKeyUpdated(checked, username, key) {
      if (checked)
        console.log(" Checked " + username);
      else
        console.log(" UnChecked " + username);

      globalController.api_set_property("/user/admin/", username, key, checked, (data) => {
        utils.runToast(` Saved `, "now-ui-icons ui-1_bell-53", "bottom-right", "info");
      },
        (error) => {
          console.log(" ERROR " + error);
          utils.runToast(` ${error} `, "now-ui-icons ui-1_bell-53", "bottom-right", "danger");
        });
    },

    remove_user(username) {
      let item = this.userTable.find(item => item.username === username);
      item['status'] = "deleted";

      globalController.api_call(
        "/user/admin/rm/" + encodeURIComponent(username),
        (result) => {
          item['status'] = "gone";
        },
        (error) => {
          console.log("Failed loading: Error loading userlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );

    },
    async loadData() {
      this.isLoaded = false;
      globalController.api_call(
        "/user/admin/get/ALL",
        (result) => {
          console.log("Userlist result");
          this.userTable = result.users;
          this.isFirstLoaded = this.isLoaded = true;
        },
        (error) => {
          console.log("Failed loading: Error loading watchlist");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },

    async loadDataServices() {
      this.isLoaded = false;

      if (this.stateInteval)
        clearInterval(this.stateInteval);

      this.stateInteval = setInterval(() => {
        globalController.api_call(
          "/prompts/state",
          (result) => {
            this.priority_size = result.priority.length;
            this.process_size = result.process.length;

            this.servicesQueueTable = [...result.priority, ...result.process];
            this.isQueueLoaded = true;
          },
          (error) => {
            console.log("Failed loading: Error loading queue");
            utils.runToast(
              ` ${error} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
          }
        );
      }, 1500);
    },
  },

  watch: {
    "userTable.item.is_admin": {
      handler(newValue, oldValue) {
        console.log(" Changed user table ");
      },
    },
  },

}
</script>


<style scoped>
.action_button {
  margin-right: 10px;
}

.form-check-sign {
  top: 0px !important;
}
</style>
